import React, { useEffect } from 'react'
import IndustryNav from './IndustryNav'
import IndustrySection from './IndustrySection'
import DefaultState from './DefaultState'
import { useIndustryContext } from '../../context/industry'

const IndustryTabs = ({ defaultState, preview = null }) => {
  const [, dispatchIndustry] = useIndustryContext()

  const clearIndustry = () => dispatchIndustry({ type: 'clear' })

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const commercialNavLink = document.querySelector('#commercial-nav-item')
      commercialNavLink.addEventListener('click', clearIndustry)
      return () => commercialNavLink.removeEventListener('click', clearIndustry)
    }
  }, [])
  return (
    <section>
      <IndustryNav preview={preview} />

      <DefaultState {...defaultState} />
      <IndustrySection />
    </section>
  )
}

export default IndustryTabs
