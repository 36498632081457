import React from 'react'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Container from '../Container'
import { H2 } from '../Headings'
import { slugify } from '../../helpers'
import { useIndustries, useIndustryContext } from '../../context/industry'

const DefaultState = ({ headline, paragraph }) => {
  const { industries } = useIndustries()
  const [{ industry }] = useIndustryContext()
  if (industry || !headline || !paragraph) return null
  return (
    <section tw="text-center pt-16">
      <Container>
        <H2>{headline}</H2>
        <div
          dangerouslySetInnerHTML={{ __html: paragraph }}
          tw="mb-16 w-full md:(w-2/3) mx-auto text-gray-700"
        />
        <div tw="mb-8 grid grid-cols-2 sm:(grid-cols-3) gap-8 lg:(grid-cols-6 gap-4 px-12)">
          {industries.map(industry => (
            <Link
              to={`${industry.slug}/${slugify(
                industry.subIndustries[0].title
              )}#tabs`}
              tw="flex flex-col items-center"
              rel="nofollow"
            >
              <div tw="border-4 border-yellow-600 rounded-full h-32 w-32 xl:(h-40 w-40) flex items-center justify-center mb-4">
                <Img
                  tw="absolute transform scale-75 xl:(scale-100)"
                  fixed={
                    industry?.featuredImage?.node?.localFile?.childImageSharp
                      ?.fixed
                  }
                  loading="lazy"
                />
              </div>
              <strong css={[tw`break-words`, `word-break: break-word;`]}>
                {industry.title.split('/').map((word, index) => (
                  <>
                    {word}
                    {index < industry.title.split('/').length - 1 && (
                      <>/&#8203;</>
                    )}
                  </>
                ))}
              </strong>
            </Link>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default DefaultState
