const industryReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return Object.assign({}, state, action.payload)
    case 'clear':
      return {}
    default:
      throw new Error('Commercial Reducer -- Check your action.type')
  }
}

export default industryReducer
