import { useStaticQuery, graphql } from 'gatsby'

const useIndustries = (previewData = {}) => {
  const data = useStaticQuery(graphql`
    {
      allWpCommercialIndustry(
        sort: {
          order: ASC
          fields: commercialIndustries___subIndustries___title
        }
      ) {
        nodes {
          slug
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fixed(width: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          commercialIndustries {
            subIndustries {
              heroTitle: subindustryHeroTitle
              heroDescription: subindustryHeroDescription
              subHeroHeadline: subIndustrySubHeroHeadline
              title
              description
            }
            industryRelatedPests {
              ... on WpPest {
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        fixed(width: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const industries = data.allWpCommercialIndustry.nodes.map(
    ({ slug, title, featuredImage, id, commercialIndustries }) => {
      if (previewData && previewData?.industry?.id === id)
        return previewData?.industry

      return {
        slug,
        title,
        featuredImage,
        id,
        ...commercialIndustries,
      }
    }
  )

  const subIndustries = industries
    .map(({ slug, title, subIndustries, id }) =>
      subIndustries.map(subIndustry => {
        if (id !== previewData?.id)
          return { ...subIndustry, industry: title, industryId: id }
      })
    )
    .filter(item => item !== null)
    .flat()

  if (previewData && Array.isArray(previewData?.subIndustries)) {
    previewData.subIndustries.forEach(subIndustry =>
      subIndustries.push(subIndustry)
    )
  }

  return { industries, subIndustries }
}

export default useIndustries
