import React from 'react'
import 'twin.macro'
import Img from 'gatsby-image'

import { ALink } from '../A'
import { H3 } from '../Headings'
import { useIndustryContext } from '../../context/industry'

const IndustryRelatedPests = () => {
  const [{ industry }] = useIndustryContext()

  if (!industry || !'industryRelatedPests' in industry) return null

  const { industryRelatedPests } = industry
  return (
    <section>
      <H3 tw="font-normal mb-4">
        We understand the pests common to your industry…
      </H3>
      <div tw="grid grid-cols-2 gap-4 md:(grid-cols-5)">
        {industryRelatedPests &&
          industryRelatedPests.map(({ title, slug, featuredImage }) => (
            <ALink
              to={`/learn/${slug}`}
              tw="text-base font-bold text-center border rounded-lg hover:(cursor-pointer shadow-lg)"
            >
              <div tw="h-24 flex items-center justify-center">
                {featuredImage?.node && (
                  <>
                    {featuredImage?.node?.localFile?.childImageSharp?.fixed ? (
                      <Img
                        tw="transform scale-100"
                        style={{ maxWidth: '100%' }}
                        fixed={
                          featuredImage?.node.localFile.childImageSharp.fixed
                        }
                        loading="lazy"
                      />
                    ) : (
                      <img
                        tw="w-24"
                        src={featuredImage?.node?.sourceUrl}
                        loading="lazy"
                      />
                    )}
                  </>
                )}
              </div>
              <span tw="text-base block mb-4 text-black">{title}</span>
            </ALink>
          ))}
      </div>
    </section>
  )
}

export default IndustryRelatedPests
