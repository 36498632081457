import React, { createContext, useContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import industryReducer from './industry.reducer'
import useIndustries from './useIndustries'
import { slugify } from '../../helpers'

const IndustryContext = createContext(null)

const IndustryProvider = ({ children, preview, ...params }) => {
  const { industries, subIndustries } = useIndustries(preview)

  const initialState = {}

  if (!preview && 'industry' in params && 'subindustry' in params) {
    const [industry] = industries.filter(({ slug }) => params.industry === slug)
    const [subIndustry] = subIndustries.filter(
      ({ title }) => slugify(title) === params.subindustry
    )

    Object.assign(initialState, { industry, subIndustry })
  } else if (
    preview &&
    Array.isArray(preview?.industries) &&
    Array.isArray(preview?.subIndustries)
  ) {
    console.log({ preview })
    const [industry] = preview.industries
    const [subIndustry] = preview.subIndustries

    Object.assign(initialState, { industry, subIndustry, preview: true })
  }

  const [industryState, dispatchIndustry] = useReducer(
    industryReducer,
    initialState
  )

  useEffect(() => {
    console.log({ industryState })
  }, [industryState])

  return (
    <IndustryContext.Provider value={[industryState, dispatchIndustry]}>
      {children}
    </IndustryContext.Provider>
  )
}

IndustryProvider.propTypes = {
  children: PropTypes.node.isRequired,
  industry: PropTypes.string,
  subindustries: PropTypes.string,
}

const useIndustryContext = () => useContext(IndustryContext)

export {
  IndustryProvider as default,
  useIndustryContext,
  IndustryContext,
  useIndustries,
}
