import React from 'react'
import 'twin.macro'
import Img from 'gatsby-image'
import Container from '../Container'
import { H2, H4 } from '../Headings'

const Card = ({ headline, paragraph, icon }) => (
  <div tw="text-center flex flex-col content-start">
    {icon && (
      <div tw="mt-8 w-32 h-32 p-8 mx-auto rounded-full bg-yellow-600 mb-5">
        {icon?.localFile?.childImageSharp?.fixed ? (
          <Img fixed={icon?.localFile?.childImageSharp?.fixed} loading="lazy" />
        ) : (
          <img src={icon?.sourceUrl} loading="lazy" />
        )}
      </div>
    )}
    <h2 tw="mb-2 text-2xl">{headline}</h2>
    <p tw="text-gray-700">{paragraph}</p>
  </div>
)

const CardGroup = ({ headline, paragraph, cards }) => (
  <section tw="py-16 mt-10 bg-gray-50" aria-label="Upgrade to Cook's">
    <Container>
      <div tw="text-center">
        <H2 tw="text-center text-2xl">{headline}</H2>
        <p tw="text-base text-gray-700">{paragraph}</p>
      </div>
      {cards && cards.length ? (
        <div tw="md:(grid grid-cols-2 gap-8) lg:(grid-cols-4)">
          {cards.map(({ headline, paragraph, icon }) => (
            <Card headline={headline} paragraph={paragraph} icon={icon} />
          ))}
        </div>
      ) : null}
    </Container>
  </section>
)

CardGroup.defaultProps = {
  headline: null,
  paragraph: null,
  cards: null,
}

export default CardGroup
