import { useStaticQuery, graphql } from 'gatsby'

const useStaticIndustryPreview = () =>
  useStaticQuery(graphql`
    {
      wpPage(id: { eq: "cG9zdDoxMTQ2Mw==" }) {
        seo {
          ...PostSEO
        }
        commercialServices {
          hero {
            paragraph
            headline
            ctaButton {
              title
              url
            }
            background {
              localFile {
                publicURL
              }
            }
          }
          footerCta {
            ctaButton {
              title
              url
            }
            headline
            paragraph
          }
          cardGroup {
            headline
            paragraph
            cards {
              headline
              paragraph
              icon {
                localFile {
                  childImageSharp {
                    fixed(width: 300) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
          industriesHeading {
            headline
            paragraph
          }
        }
      }
    }
  `)

export default useStaticIndustryPreview
