import React from 'react'
import tw from 'twin.macro'
import { H2 } from '../Headings'
import { useIndustryContext } from '../../context/industry'

const SubIndustrySection = () => {
  const [{ subIndustry }] = useIndustryContext()

  if (subIndustry) {
    return (
      <article css={[tw`mb-8`]}>
        <H2 tw="mb-2">{subIndustry.title}</H2>
        <div
          tw="prose max-w-full"
          dangerouslySetInnerHTML={{
            __html: subIndustry.description,
          }}
        />
      </article>
    )
  }
  return null
}

export default SubIndustrySection
