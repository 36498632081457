import React from 'react'
import Container from '../Container'
import tw from 'twin.macro'
import { useIndustries, useIndustryContext } from '../../context/industry'
import { slugify } from '../../helpers'

const IndustryNav = ({ activeIndustry, preview }) => {
  const { industries } = useIndustries(preview)
  const [
    { industry, subIndustry },
    dispatchIndustryState,
  ] = useIndustryContext()

  return (
    <div tw="w-full bg-blue-600 text-white font-normal py-4 z-20" id="tabs">
      <Container>
        <nav aria-label="Industries">
          <ul
            tw="flex flex-col content-center md:(flex-row justify-between text-lg font-normal)"
            aria-label="Industries"
          >
            {industries.map((currentIndustry, i) => {
              const isActive = industry?.slug
                ? industry.slug === currentIndustry.slug
                : false
              const { slug, title, subIndustries } = currentIndustry
              const [firstSubIndustry] = subIndustries

              return (
                <li tw="text-center">
                  <button
                    css={[
                      tw`px-1 py-1 font-bold text-gray-100 border-yellow-600 sm:(font-normal) hover:(text-yellow-600)`,
                      isActive && tw`border-b-4`,
                    ]}
                    onClick={() => {
                      window.history.pushState(
                        null,
                        null,
                        `/commercial/${slug}/${slugify(
                          firstSubIndustry?.title
                        )}`
                      )

                      const payload = {
                        industry: currentIndustry,
                        subIndustry: firstSubIndustry,
                      }

                      dispatchIndustryState({
                        type: 'update',
                        payload,
                      })
                    }}
                    aria-current={isActive}
                  >
                    {title}
                  </button>
                </li>
              )
            })}
          </ul>
        </nav>
      </Container>
    </div>
  )
}

export default IndustryNav
