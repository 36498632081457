import React from 'react'
import tw from 'twin.macro'
import Container from '../Container'
import IndustryRelatedPests from './IndustryRelatedPests'

import SubIndustryNav from './SubIndustryNav'
import SubIndustrySection from './SubIndustrySection'
import { useIndustryContext } from '../../context/industry'

const IndustrySection = ({
  active,
  activeSubIndustry,
  setActiveSubIndustry,
}) => {
  const [{ industry }] = useIndustryContext()

  if (!industry) return null
  const { industryRelatedPests } = industry
  return (
    <Container
      css={[
        tw`md:(grid-cols-3) lg:(grid-cols-4) py-6`,
        tw`flex flex-col md:(grid)`,
      ]}
    >
      <SubIndustryNav />
      <div tw="md:(border-l border-gray-400 pl-12) col-span-2 lg:(col-span-3)">
        <SubIndustrySection />
        <IndustryRelatedPests />
      </div>
    </Container>
  )
}

export default IndustrySection
