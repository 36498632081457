import React from 'react'
import tw from 'twin.macro'
import Container from '../Container'
import { ButtonLink } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { H1 } from '../Headings'
import { useIndustries, useIndustryContext } from '../../context/industry'

const IndustryHero = ({ hero, industriesHeading }) => {
  const { industries, subIndustries } = useIndustries()
  const [{ industry, subIndustry }] = useIndustryContext()

  return (
    <section tw="relative">
      <div tw="overflow-hidden h-full w-full absolute z-0">
        {hero?.background && (
          <>
            {hero?.background?.localFile?.publicURL ? (
              <img
                src={hero.background.localFile.publicURL}
                style={{ position: 'absolute' }}
                css={[
                  tw`min-w-full min-h-full object-cover transform -translate-x-1/2 -translate-y-1/2 opacity-75`,
                  'z-index: -1; top:50%;left:50%;',
                ]}
                loading="lazy"
              />
            ) : (
              <div
                css={[
                  tw`min-w-full min-h-full max-w-full max-h-full absolute bg-no-repeat bg-center bg-cover`,
                  `background-image: url('${hero.background?.sourceUrl}');`,
                ]}
              />
            )}
          </>
        )}

        <div
          css={[
            tw`absolute w-full h-full z-10 opacity-75`,
            `background-image: linear-gradient(145deg, #ffffff 41%, rgba(255, 255, 255, 0.52) 55%, rgba(128, 128, 128, 0) 83%);`,
          ]}
        />
      </div>
      <Container tw="relative py-10 z-20">
        <div tw="text-center sm:(text-left) lg:(w-2/3)">
          <H1 tw="text-4xl text-center sm:(text-left) mb-5">
            {subIndustry && subIndustry?.heroTitle
              ? subIndustry?.heroTitle
              : hero.headline}
          </H1>
          {subIndustry?.heroDescription ? (
            <p tw="text-base text-center sm:(text-left text-xl leading-10) mb-5 lg:(w-4/5)">
              {subIndustry?.heroDescription}
            </p>
          ) : (
            <p tw="text-base mb-5 sm:(text-xl leading-10) lg:(w-4/5)">
              {hero.paragraph}
            </p>
          )}

          {hero?.ctaButton && (
            <ButtonLink tw="w-full sm:(w-auto)" to={hero.ctaButton.url}>
              <span tw="mr-5">{hero.ctaButton.title}</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </ButtonLink>
          )}
        </div>
      </Container>

      <div tw="relative w-full bg-white bg-opacity-75 z-30 py-5">
        <Container>
          {/* {(subIndustry?.subHeroHeadline) 
                  ? (
                    <p tw="text-5xl font-bold text-center">
                      {subIndustry?.subHeroHeadline}
                    </p>
                  ) : (
                    <p tw="text-5xl font-bold text-center">{industriesHeading.headline}</p>
                  )} */}

          <h2 tw="text-2xl font-bold text-center sm:(text-5xl)">
            {industriesHeading.headline}
          </h2>

          {industriesHeading?.paragraph && (
            <p tw="text-base text-center w-10/12 mx-auto sm:(text-lg text-center w-7/12 mx-auto)">
              {industriesHeading?.paragraph}
            </p>
          )}
        </Container>
      </div>
    </section>
  )
}

export default IndustryHero
