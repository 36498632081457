import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'

import Layout from '../../../components/Layout'
import { H1 } from '../../../components/Headings'
import Img from 'gatsby-image'
import Container from '../../../components/Container'
import { ButtonLink } from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import IndustryTabs from '../../../components/Industry/IndustryTabs'
import CardGroup from '../../../components/Industry/CardGroup'
import FooterCTA from '../../../components/FooterCTA'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../../helpers'
import IndustryProvider from '../../../context/industry'
import IndustryHero from '../../../components/Industry/IndustryHero'
import useStaticIndustryPreview from '../../../context/industry/useStaticIndustryPreview'

const PageTemplate = ({ data, params, location, preview }) => {
  const staticPreviewData = useStaticIndustryPreview()

  const pageData = preview ? staticPreviewData?.wpPage : data.wpPage
  const {
    hero,
    industriesHeading,
    defaultState,
    cardGroup,
    footerCta,
  } = pageData.commercialServices

  return (
    <IndustryProvider {...params} preview={preview}>
      <Layout headerType="commercial" location={location} commercialPestControl>
        {!preview && (
          <Seo
            page={formatYoastSeo(data)}
            title="Commercial Industries - Cook's Pest Control"
          />
        )}

        {/* Hero */}
        <IndustryHero hero={hero} industriesHeading={industriesHeading} />

        {/* Industries Section */}
        <IndustryTabs
          activeIndustrySlug={params.industry}
          activeSubIndustrySlug={params.subindustry}
          defaultState={defaultState || null}
          preview={preview}
        />

        {/* Card Group */}
        <CardGroup
          headline={cardGroup.headline}
          paragraph={cardGroup.paragraph}
          cards={cardGroup.cards}
        />

        {/* Footer CTA */}
        <FooterCTA
          headline={footerCta.headline}
          paragraph={footerCta.paragraph}
          ctaText={footerCta.ctaButton?.title}
          ctaUrl={footerCta.ctaButton?.url}
        />
      </Layout>
    </IndustryProvider>
  )
}

export default PageTemplate

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxMTQ2Mw==" }) {
      seo {
        ...PostSEO
      }
      commercialServices {
        hero {
          paragraph
          headline
          ctaButton {
            title
            url
          }
          background {
            localFile {
              publicURL
            }
          }
        }
        footerCta {
          ctaButton {
            title
            url
          }
          headline
          paragraph
        }
        cardGroup {
          headline
          paragraph
          cards {
            headline
            paragraph
            icon {
              localFile {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
        industriesHeading {
          headline
          paragraph
        }
      }
    }
  }
`
