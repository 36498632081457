import React from 'react'
import tw from 'twin.macro'
import { useIndustryContext } from '../../context/industry'
import { slugify } from '../../helpers'

const SubIndustryNav = () => {
  const [
    { industry, subIndustry },
    dispatchIndustryState,
  ] = useIndustryContext()

  return (
    <nav
      tw="pr-0 flex flex-col mb-4 md:(pr-12 mb-0)"
      aria-label="Sub Industries"
    >
      {Array.isArray(industry?.subIndustries) &&
        industry.subIndustries.map(currentSubIndustry => {
          const isActive = currentSubIndustry.title === subIndustry?.title
          return (
            <button
              onClick={() => {
                window.history.pushState(
                  null,
                  null,
                  `/commercial/${industry.slug}/${slugify(
                    currentSubIndustry?.title
                  )}`
                )
                dispatchIndustryState({
                  type: 'update',
                  payload: { subIndustry: currentSubIndustry },
                })
              }}
              css={[
                tw`text-center px-3 py-1 mb-3 text-blue-600 rounded text-base border border-blue-600`,
                isActive && tw`border-blue-600 bg-blue-600 text-white`,
              ]}
            >
              {currentSubIndustry.title}
            </button>
          )
        })}
    </nav>
  )
}

export default SubIndustryNav
